import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Container,
  Link,
  Box,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import Section1 from "../assets/images/for-jobseekers.svg";
import Section2 from "../assets/images/search-for.svg";
import Section3 from "../assets/images/get-hired.svg";
import Section4 from "../assets/images/standout-resumes.svg";
import Section5 from "../assets/images/unlock-opportunities.svg";
import Section6 from "../assets/images/expert-career.svg";
import KeycloakService from "../keycloak";
import { addDataToKeycloakRedirectURI } from "../helpers/helper";

export default function HomePage() {
  let navigate = useNavigate();
  const isAuthenticated = KeycloakService.isLoggedIn();
  const [bionic, setBionic] = useState(
    localStorage.getItem("isBionic") ? localStorage.getItem("isBionic") : null
  );
  const [fixation, setFixation] = useState(
    localStorage.getItem("isBionic")
      ? JSON.parse(localStorage.getItem("bionic"))
      : null
  );
  window.scrollTo(0, 0);

  const NormalFormat = (data, fixation) => {
    let color = fixation?.color;
    let fontSize = fixation?.font;
    let highlightedText;
    let coloredHtmlString;
    if (fixation?.bionicSelect === "bold") {
      highlightedText = textVide.textVide(data, {
        fixationPoint: fixation?.fixation,
        sep: ["<b>", "</b>"],
      });
      coloredHtmlString = highlightedText.replace(
        /<b>(.*?)<\/b>/g,
        `<b style="color: ${color} !important; font-size: ${fontSize}px !important">$1</b>`
      );
    } else {
      highlightedText = textVide.textVide(data, {
        fixationPoint: fixation?.fixation,
        sep: ["<u>", "</u>"],
      });
      coloredHtmlString = highlightedText.replace(
        /<u>(.*?)<\/u>/g,
        `<u style="color: ${color} !important; font-size: ${fontSize}px !important">$1</u>`
      );
    }
    const htmlContent = `<span>${coloredHtmlString}</span>`;
    return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
  };
  useEffect(() => {
    document.querySelector("link[title=icon]").setAttribute("href", "/favicon.png");
    const handleStorage = () => {
      if (localStorage.getItem("isBionic")) {
        setBionic(localStorage.getItem("isBionic"));
        setFixation(JSON.parse(localStorage.getItem("bionic")));
      } else {
        setBionic(null);
        setFixation(null);
      }
    };
    window.addEventListener("storage", handleStorage);
    return () => window.removeEventListener("storage", handleStorage);
  }, []);

  return (
    <Grid className="body-container Launch-page-pad" sx={{ px: 4 }}>
      <Grid
        item
        lg={12}
        md={12}
        xs={12}
        spacing={1}
        className="align-item-center p-20 section1 mt-36"
      >
        <Grid item lg={10} md={12} xs={12} className="align-item-center py-15">
          <Grid item lg={12} md={12} xs={12}>
            <h3 className="font-47 text-center m-0 font-weight-600">
              Job Seekers
            </h3>
            <Typography className="f-14 launch-page-font text-center font-weight-400 px-245">
              <p>
                {bionic
                  ? NormalFormat(
                      "Discover the power of Direct Source, the premier direct sourcing technology solution for contingent workforce management. Our platform offers innovative tools and resources, connecting you with top opportunities and empowering you to take control of your career.",
                      fixation
                    )
                  : "Discover the power of Direct Source, the premier direct sourcing technology solution for contingent workforce management. Our platform offers innovative tools and resources, connecting you with top opportunities and empowering you to take control of your career."}
              </p>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {/* <Container className="index-issue p-0" id='home-page'> */}
      <Grid className="align-item-center py-20 pt-80">
        <Grid
          container
          lg={10}
          md={12}
          xs={12}
          spacing={1}
          className="align-item-center py-15 m-auto"
        >
          <Grid item lg={6} md={6} xs={12} className="find-job-input">
            <Grid className="py-10">
              <h3 className="launch-page-title text-left m-0">
                Take control of your career path
              </h3>
              <div className="section2"></div>
            </Grid>
            <Typography className="f-14 launch-page-font text-left font-weight-400">
              <p>
                {bionic
                  ? NormalFormat(
                      "Create your profile on hireHQ to access top contingent work opportunities. Showcase your skills and experience to potential employers and take control of your contingent work journey today",
                      fixation
                    )
                  : "Create your profile on hireHQ to access top contingent work opportunities. Showcase your skills and experience to potential employers and take control of your contingent work journey today"}
                .
              </p>
            </Typography>
            <Grid className="align-item-center py-15 justify-flex-start cursor-hover">
              <a
                className="btn primary-button text-center text-decoration-none"
                onClick={() => KeycloakService.doRegisteration({ redirectUri: addDataToKeycloakRedirectURI(`${window.location.origin}/candidate/create-profile`) })}
              >
                Create a Profile
              </a>
            </Grid>
          </Grid>

          <Grid
            item
            lg={6}
            md={6}
            xs={12}
            className="justifyCenter Launch-page-mar content-right-padding"
          >
            <img
              src={Section1}
              alt="image"
              className="policy-responsive"
              width="70%"
              height="50%"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        lg={12}
        md={12}
        xs={12}
        className="align-item-center py-15 pt-80"
      >
        <Grid
          container
          lg={10}
          md={12}
          xs={12}
          spacing={1}
          className="align-item-center py-20 m-auto"
        >
          <Grid item lg={6} md={6} xs={12} className="find-job-input">
            <Grid className="py-10">
              <h3 className="launch-page-title text-left m-0">
                Search for jobs anywhere, anytime
              </h3>
              <div className="section2"></div>
            </Grid>
            <Typography className="f-14 launch-page-font text-left font-weight-400">
              <p>
                {bionic
                  ? NormalFormat(
                      "Direct Source is designed to offer a mobile-enabled experience with an easy-to-use UI/UX that ensures you can access and apply for jobs on the go. Whether you’re commuting, waiting in line, or just taking a break, our platform is accessible and user-friendly.",
                      fixation
                    )
                  : "Direct Source is designed to offer a mobile-enabled experience with an easy-to-use UI/UX that ensures you can access and apply for jobs on the go. Whether you’re commuting, waiting in line, or just taking a break, our platform is accessible and user-friendly."}
              </p>
            </Typography>
          </Grid>

          <Grid
            item
            lg={6}
            md={6}
            xs={12}
            className="justifyCenter Launch-page-mar content-right-padding"
          >
            <img
              src={Section2}
              alt="image"
              className="policy-responsive"
              width="90%"
              height="50%"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        lg={12}
        md={12}
        xs={12}
        className="align-item-center py-15 pt-80"
      >
        <Grid
          container
          lg={10}
          md={12}
          xs={12}
          spacing={1}
          className="align-item-center py-20"
        >
          <Grid
            item
            lg={6}
            md={6}
            xs={12}
            className="justifyCenter Launch-page-mar content-left-padding"
          >
            <img
              src={Section3}
              alt="image"
              width="90%"
              height="50%"
              className="policy-responsive"
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} className="find-job-input">
            <Grid className="py-10">
              <h3 className="launch-page-title text-left m-0">
                Get hired faster for the jobs you want
              </h3>
              <div className="section2"></div>
            </Grid>
            <Typography className="f-14 launch-page-font text-left font-weight-400">
              <p>
                {bionic
                  ? NormalFormat(
                      "With our streamlined job search and personalized job recommendations, you can find the best contingent work opportunities that match your skills and experience faster than ever before.",
                      fixation
                    )
                  : "With our streamlined job search and personalized job recommendations, you can find the best contingent work opportunities that match your skills and experience faster than ever before."}
              </p>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        lg={12}
        md={12}
        xs={12}
        className="align-item-center py-15 pt-80"
      >
        <Grid
          container
          lg={10}
          md={12}
          xs={12}
          spacing={1}
          className="align-item-center py-20"
        >
          <Grid item lg={6} md={6} xs={12} className="find-job-input">
            <Grid className="py-10">
              <h3 className="launch-page-title text-left m-0">
                Not just a resume, a dynamic profile
              </h3>
              <div className="section2"></div>
            </Grid>
            <Typography className="f-14 launch-page-font text-left font-weight-400">
              <p>
                {bionic
                  ? NormalFormat(
                      "Keep your candidate profile up-to-date and relevant with our dynamic profile feature. Add new skills and experiences as you grow in your career and create video pitches to showcase your unique personality and strengths. With a better matching score, you’ll have a greater chance of landing the job you want.",
                      fixation
                    )
                  : "Keep your candidate profile up-to-date and relevant with our dynamic profile feature. Add new skills and experiences as you grow in your career and create video pitches to showcase your unique personality and strengths. With a better matching score, you’ll have a greater chance of landing the job you want."}
              </p>
            </Typography>
          </Grid>

          <Grid
            item
            lg={6}
            md={6}
            xs={12}
            className="justifyCenter Launch-page-mar"
          >
            <img
              src={Section4}
              alt="image"
              width="90%"
              height="50%"
              className="policy-responsive content-right-padding"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        lg={12}
        md={12}
        xs={12}
        className="align-item-center py-15 pt-80"
      >
        <Grid
          container
          lg={10}
          md={12}
          xs={12}
          spacing={1}
          className="align-item-center py-20"
        >
          <Grid
            item
            lg={6}
            md={6}
            xs={12}
            className="justifyCenter Launch-page-mar content-left-padding"
          >
            <img
              src={Section5}
              alt="image"
              width="90%"
              height="50%"
              className="policy-responsive"
            />
          </Grid>

          <Grid item lg={6} md={6} xs={12} className="find-job-input">
            <Grid className="py-10">
              <h3 className="launch-page-title text-left m-0">
                Opportunity to present yourself leading companies
              </h3>
              <div className="section2"></div>
            </Grid>
            <Typography className="f-14 launch-page-font text-left font-weight-400">
              <p>
                {bionic
                  ? NormalFormat(
                      "Direct Source connects you with top companies and organizations that are looking for talented contingent workers like you, giving you the opportunity to collaborate with the best in your field.",
                      fixation
                    )
                  : "Direct Source connects you with top companies and organizations that are looking for talented contingent workers like you, giving you the opportunity to collaborate with the best in your field."}
              </p>
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        lg={12}
        md={12}
        xs={12}
        className="align-item-center py-15 pt-80"
      >
        <Grid
          container
          lg={10}
          md={12}
          xs={12}
          spacing={1}
          className="align-item-center py-20"
        >
          <Grid item lg={6} md={12} xs={12} className="find-job-input">
            <Grid className="py-10">
              <h3 className="launch-page-title text-left m-0">
                Expert career guidance at your fingertips
              </h3>
              <div className="section2"></div>
            </Grid>
            <Typography className="f-14 launch-page-font text-left font-weight-400">
              <p>
                {bionic
                  ? NormalFormat(
                      "A virtual coach for personalized advice and career development, as well as career pathing resources for achieving your goals. Elevate your career in contingent work with our expert guidance and resources.",
                      fixation
                    )
                  : "A virtual coach for personalized advice and career development, as well as career pathing resources for achieving your goals. Elevate your career in contingent work with our expert guidance and resources."}
              </p>
            </Typography>
          </Grid>

          <Grid
            item
            lg={6}
            md={6}
            xs={12}
            className="justifyCenter Launch-page-mar content-right-padding"
          >
            <img
              src={Section6}
              alt="image"
              width="90%"
              height="50%"
              className="policy-responsive"
            />
          </Grid>
        </Grid>
      </Grid>

      {/* </Container> */}
      {/* <Grid data-aos="zoom-in-up" item lg={12} md={12} xs={12} spacing={1} className="align-item-center p-20 section1 mb-70 mt-80">
                <Grid item lg={10} md={12} xs={12} className="align-item-center">
                    <Grid item lg={12} md={12} xs={12}>
                        <h3 className="launch-page-title text-center m-0">
                            Reach Out To Us
                        </h3>

                        <Typography className="f-14 launch-page-font text-center font-weight-400">
                            <p>Get in touch today to learn how we can help you achieve your contingent hiring goals!</p>
                        </Typography>
                        <Grid className="align-item-center py-15 mt-20">
                            <a href="#contactus" className="btn learn-how-button cursor-hover text-center text-decoration-none">Contact Us</a>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid> */}
    </Grid>
  );
}
