import React, { useEffect, useState } from 'react';
import '../../../assets/styles/ChatWindow.css'; // Create your CSS styles
import { Typography, IconButton, Menu, MenuItem } from '@mui/material';
import { checkIsImage } from '../../../helpers/helper-data';
import CloseIcon from "@mui/icons-material/Close";
import MessageWindow from './MessageWindow';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ReactComponent as CollapseChat } from "../../../assets/images/collapse-chat.svg";
import ApiCalls from '../../../api/customAxios';
import { success } from '../../../Components/shared/messageBox';

const ITEM_HEIGHT = 48;

const ChatWindow = ({ candidate, index, isLastChatWindow, openSmsChat }) => {
    const [isMinimized, setIsMinimized] = useState(true);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [loaddata, setLoaddata] = useState(false);
    const [messagesLength, setMessagesLength] = useState(0);
    const openChatCTA = Boolean(anchorEl);
    const handleClose = (e) => {
      e.stopPropagation();
      setAnchorEl(null);
    };
    const handleClick = (e) => {
      e.stopPropagation();
      setAnchorEl(e.currentTarget);
    };
    useEffect(()=>{
        if (openSmsChat) {
          if (isLastChatWindow) {
            setIsMinimized(false);
            setLoaddata(!loaddata);
            setTimeout(()=>{
              localStorage.removeItem("OPEN_SMS_CHAT");
            }, 1000);
          } else {
            setIsMinimized(true);
          }
        }
    }, [openSmsChat]);

    useEffect(()=>{
      setLoaddata(!loaddata);
    }, [localStorage.getItem("SMS_CHATS")]);

    const toggleMinimize = () => {
        setIsMinimized(!isMinimized);
    };

    const handleCloseChatWindow = (e) => {
        e.stopPropagation();
        let chats = localStorage.getItem("SMS_CHATS");
        if (chats) {
            chats = JSON.parse(chats);
            chats?.splice(index, 1);
            localStorage.setItem("SMS_CHATS", JSON.stringify(chats));
        }
    };

    const handleDeleteChatHistory = (e) => {
      e.stopPropagation();
      ApiCalls.put(
        "candidates/messages/chat-history?candidateId=" +
          candidate?.id + "&enterpriseId=" + candidate.enterpriseId,
        null
      )
        .then(async(res) => {
          success("Chat history is deleted successfully.");
          handleCloseChatWindow(e);
          localStorage.setItem("isChatHistoryCleared", true);
        })
        .catch((err) => {
        });

      setAnchorEl(null);
  };

    return (
        <div className={`chat-window ${isMinimized ? 'minimized' : ''}`}>
            <div className={`chat-header ${(isMinimized || !(candidate.profileTitle || candidate.enterpriseName)) ? (!isMinimized ? "pt-20" : "") : "flex-start"}`} onClick={toggleMinimize}>
                <Typography className={`display-flex ${(isMinimized || !(candidate.profileTitle || candidate.enterpriseName)) ? "align-center" : ""}`}>
                <Typography className="mr-10 display-flex">
                          {(candidate.mediaFile === "****" ||
                            candidate.mediaFileSigned === null ||
                            candidate.mediaFileSigned === "") && (
                            <Typography
                              className="default-img-border justify-center align-center display-flex primary-shadow-color bg-input f-16 font-weight-700 text-capitalize"
                              width="40px"
                              height="40px"
                              aria-label="Default image"
                            >
                              {candidate.firstName.trim().substring(0, 1)}
                            </Typography>
                          )}
                          {candidate.mediaFile !== "****" &&
                            candidate.mediaFileSigned &&
                            candidate.mediaFileSigned !== null &&
                            candidate.mediaFileSigned !== "" &&
                            !checkIsImage(candidate.mediaFileSigned) && (
                              <video
                                width="40px"
                                height="40px"
                                className="default-img-border objectFit cursor-hover"
                                src={candidate.mediaFileSigned}
                                muted
                                tabIndex={0}
                              />
                            )}
                          {candidate.mediaFile !== "****" &&
                            candidate.mediaFileSigned &&
                            candidate.mediaFileSigned !== null &&
                            candidate.mediaFileSigned !== "" &&
                            checkIsImage(candidate.mediaFileSigned) && (
                              <img
                                width="40px"
                                height="40px"
                                src={candidate.mediaFileSigned}
                                className="default-img-border"
                                alt="img"
                              />
                            )}
                </Typography>
                <Typography>
                <Typography className={`gray9 f-16 font-weight-600 chat-title ${isMinimized ? "w-200" : "w-140"}`}>
                    {candidate.firstName} {candidate.lastName}
                </Typography>
                {!isMinimized &&
                  <Typography className="gray9 f-12 font-weight-400 chat-title w-140">
                    {candidate.profileTitle}{(candidate.profileTitle && candidate.enterpriseName) ? " - " : ""}{candidate.enterpriseName}
                  </Typography>}
                </Typography>
                </Typography>
                <Typography className={`display-flex ${(isMinimized || !(candidate.profileTitle || candidate.enterpriseName)) ? "align-center" : "mt-2"}`}>
                {!isMinimized &&
                  <>
                  <Typography item className="align-right">
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-controls={openChatCTA ? "long-menu" : undefined}
                      aria-expanded={openChatCTA ? "true" : undefined}
                      aria-haspopup="true"
                      onClick={handleClick}
                      onKeyPress={handleClick}
                      role="button"
                      className={`${(isMinimized || !(candidate.profileTitle || candidate.enterpriseName)) ? '' : 'p-t-0'}`}
                    >
                      <MoreVertIcon className="f-20" aria-label="Menu icon" />
                    </IconButton>
                    <Menu
                      id="long-menu"
                      MenuListProps={{
                        "aria-labelledby": "long-button",
                      }}
                      autoFocus={false}
                      anchorEl={anchorEl}
                      open={openChatCTA}
                      onClose={handleClose}
                      PaperProps={{
                        style: {
                          zIndex: 1301,
                          position: 'absolute',
                          // maxHeight: ITEM_HEIGHT * 4.5,
                          // width: "20ch",
                        },
                      }}
                    >
                      {messagesLength > 0 &&
                      <MenuItem tabIndex={0}
                        onClick={handleDeleteChatHistory}
                      >
                        <Typography className="text-capitalize gray9 f-12 font-weight-400">
                          Clear Chat
                        </Typography>
                      </MenuItem>}
                      <MenuItem tabIndex={0}
                        onClick={(e)=>{
                          e.stopPropagation();
                          window.open("/candidate/message-list?candidateId="+candidate?.id+"&candidateDetails="+window.btoa(JSON.stringify(candidate)));
                          setAnchorEl(null);
                        }}
                      >
                        <Typography className="text-capitalize gray9 f-12 font-weight-400">
                          Open in New Tab
                        </Typography>
                      </MenuItem>
                    </Menu>
                  </Typography>
                  <CollapseChat
                      aria-label="Collapse icon"
                      className="mr-10"
                      width="20px"
                      height="20px"
                      onClick={(e)=>{
                        e.stopPropagation();
                        setIsMinimized(true);
                      }}
                  />
                  </>}
                  <CloseIcon
                      onClick={handleCloseChatWindow}
                      onKeyPress={handleCloseChatWindow}
                      aria-label="Close icon"
                      width="20px"
                      height="20px"
                  />
                </Typography>
            </div>
            {!isMinimized && (
                <div className="chat-body">
                    <MessageWindow
                      candidate={candidate}
                      loaddata={loaddata}
                      setMessagesLength={setMessagesLength}
                    />
                </div>
            )}
        </div>
    );
};

export default ChatWindow;
