import { React, useEffect, useRef, useState } from 'react';
import { Box, Button, Grid, Modal, Typography, TextField, useTheme, useMediaQuery } from "@mui/material";
import { ReactComponent as Chatbot } from '../../assets/images/chatbot.svg';
import { ReactComponent as ChatbotHeaderIcon } from '../../assets/images/chatbot_header_icon.svg';
import { ReactComponent as MinimizeIcon } from '../../assets/images/minimize-icon.svg';
import { ReactComponent as ExpandIcon } from '../../assets/images/chatExpandIcon.svg';
import { ReactComponent as DeExpandIcon } from '../../assets/images/chatDeExpandIcon.svg';
import InputAdornment from "@mui/material/InputAdornment";
import { ReactComponent as SendIcon } from '../../assets/images/chatbot-send-icon.svg';
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import attachmentIcon from "../../assets/images/attachment-icon.svg";
import moment from 'moment';
import { getValidFullName, handleSpaces } from '../../helpers/helper-data';
import PasswordWidget from './PasswordWidget';
import LinkWidget from './LinkWidget';
import JobcardWidget from './JobcardWidget';
import OptionsWidget from './OptionsWidget';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { authHeader } from '../../helpers/auth-header.js';
import Draggable from 'react-draggable';
import { isSessionValid } from '../../helpers/helper.js';
import ChatbotFeedback from './ChatbotFeedback.js';
import ChatbotGreetingPopper from './ChatbotGreetingPopper.js';
import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import StopCircleOutlinedIcon from '@mui/icons-material/StopCircleOutlined';
import { ReactComponent as ChatbotInfoIcon } from '../../assets/images/chatbot-info-icon.svg';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import ChatbotInfo from './ChatbotInfo.js';

let source = axios.CancelToken.source();
const chatbox = () => {
    const candidateInfo = useSelector((state) => state.profile.candidate);
    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));
    const [openChat, setOpenChat] = useState(false);
    const [chatMessages, setChatMessages] = useState([]);
    const [currentUserValue, setCurrentUserValue] = useState("");
    const [previousUserValue, setPreviousUserValue] = useState("");
    const [isTextfieldDisabled, setIsTextfieldDisabled] = useState(false);
    const [showChatCrossIcon, setShowChatCrossIcon] = useState(false);
    const [showDragCursor, setShowDragCursor] = useState(false);
    const [isDragging, setIsDragging] = useState(false);
    const [isChatExpanded, setIsChatExpanded] = useState(false);
    const [callWelcomeOnLogin, setCallWelcomeOnLogin] = useState(false);
    const [showFeedbackMsg, setShowFeedbackMsg] = useState(false);
    const [showChatInfo, setShowChatInfo] = useState(false);
    const [selectedFeedbackOption, setSelectedFeedbackOption] = useState("close_chat");
    const [isMicOn, setIsMicOn] = useState(false);
    const [isShowInputErrMsg, setIsShowInputErrMsg] = useState(false);
    const [refreshTime, setRefreshTime] = useState(false);
    const messageContainerRef = useRef();
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [candidatePolicy, setCandidatePolicy] = useState("");
    const openChatbotGreetingPopover = Boolean(anchorEl2);
    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition,
        isMicrophoneAvailable
    } = useSpeechRecognition();

    useEffect(()=>{
        if (isMicOn) {
            setPreviousUserValue(currentUserValue);
            SpeechRecognition.startListening();
        }
    }, [listening]);
    useEffect(()=>{
        if (localStorage.getItem("PolicyAcceptanceStatus")) {
            setCandidatePolicy(localStorage.getItem("PolicyAcceptanceStatus"));
        }
      }, [localStorage.getItem("PolicyAcceptanceStatus")]);
    useEffect(()=>{
        // refreshing time for every hour
        const timer = setInterval(()=>{
            setRefreshTime((prevRefreshTime)=>!prevRefreshTime);
        }, 3600000);
        return () => clearInterval(timer);
    }, []);

    const getChatWidget = (type, response, intent = '') => {
        let widget = response;
        switch (type) {
            case "password":
                widget = <PasswordWidget email={response}/>;
                break;
            case "link":
                widget = <LinkWidget linkUrl={response.action} caption={response.button_caption} intent={intent} />;
                break;
            case "card":
                widget = <JobcardWidget jobs={response} isChatExpanded={isChatExpanded} />;
                break;
            case "options":
                widget = <OptionsWidget options={response} callBlipService={callBlipService} />;
                break;
            case "loading":
                widget = (<Typography className={`chatbot-message align-left pt-10 pb-10 pl-15 pr-15 f-14 display-flex inputcontrasts word-break`}>
                            <Box className="chatbot-typing">
                                <Typography className='chatbot-typing-dot'></Typography>
                                <Typography className='chatbot-typing-dot'></Typography>
                                <Typography className='chatbot-typing-dot'></Typography>
                            </Box>
                         </Typography>);
                break;
        }
        return widget;
    };

    function getCookie(name) {
        var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
        return v ? v[2] : null;
    }

    function setCookie(name, value, days) {
        var d = new Date;
        d.setTime(d.getTime() + 24*60*60*1000*days);
        document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
    }

    function deleteCookie(name) {
       setCookie(name, '', -1);
    }


    function guid() {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000)
          .toString(16)
          .substring(1);
      }
      return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }

    useEffect(()=>{
        if (!getCookie("isChatbotBeforeGreetingMsgClosed")) {
            setAnchorEl2(document.getElementById("chatbot_logo"));
        } else {
            setAnchorEl2(null);
        }
        dragElement(document.getElementById("chatbot-draggable-container"));
        if (openChat) {
            localStorage.setItem("isBlipChatbotOpen", true);
            setTimeout(()=>{
                setShowChatCrossIcon(true);
            }, 500);
            document.getElementById("chatbot-user-textbox")?.focus();
            let ck = getCookie('user_session');
            if ( ck == null || ck === '' ) {
                let cdef = guid();
                cdef = cdef.replaceAll('-', '');
                setCookie('user_session', cdef, 360);
                if (localStorage.getItem("blipChatMessages")) {
                    try {
                        let messages = chatMessages?.length ? chatMessages : JSON.parse(localStorage.getItem("blipChatMessages"));
                        if (messages.length) {
                            setChatMessages(messages);
                            if (callWelcomeOnLogin) {
                                callBlipService("welcome");
                                setCallWelcomeOnLogin(false);
                            }
                        } else {
                            callBlipService("welcome");
                            setCallWelcomeOnLogin(false);
                        }
                    } catch (err) {}
                } else {
                    callBlipService("welcome");
                    setCallWelcomeOnLogin(false);
                }
            } else {
                if (localStorage.getItem("blipChatMessages")) {
                    try {
                        let messages = chatMessages?.length ? chatMessages : JSON.parse(localStorage.getItem("blipChatMessages"));
                        if (messages.length) {
                            setChatMessages(messages);
                            if (callWelcomeOnLogin) {
                                callBlipService("welcome");
                                setCallWelcomeOnLogin(false);
                            }
                        } else {
                            callBlipService("welcome");
                            setCallWelcomeOnLogin(false);
                        }
                    } catch (err) {}
                } else if (chatMessages?.length === 0) {
                    callBlipService("welcome");
                    setCallWelcomeOnLogin(false);
                }
            }
        } else {
            localStorage.removeItem("isBlipChatbotOpen");
            if (browserSupportsSpeechRecognition && listening) {
                setIsMicOn(false);
                SpeechRecognition.stopListening();
            }
            setShowFeedbackMsg(false);
            setShowChatInfo(false);
            setSelectedFeedbackOption("close_chat");
            setShowChatCrossIcon(false);
            let messages = [];
            let userMsgs = [];
            try {
                messages = (chatMessages?.length ? chatMessages : JSON.parse(localStorage.getItem("blipChatMessages")));
            } catch (err) {}
            userMsgs = messages?.filter((msg)=>(msg.user == true));
            if (localStorage.getItem("token") && !localStorage.getItem("openedChatbotOnLogin")) {
                localStorage.setItem("openedChatbotOnLogin", true);
                localStorage.removeItem("blipChatMessages");
                if (userMsgs?.length) {
                    setCallWelcomeOnLogin(true);
                    setTimeout(()=>{
                        setOpenChat(true);
                        handleCloseChatbotGreetingPopover();
                    }, 1500);
                }
            }
        }
    }, [openChat]);

    useEffect(()=>{
        if (chatMessages?.length) {
            if (chatMessages[chatMessages?.length - 1].type === "loading") {
                if (chatMessages?.length > 1) {
                    localStorage.setItem("blipChatMessages", JSON.stringify(chatMessages.slice(0, chatMessages?.length - 1)));
                }
            } else {
                localStorage.setItem("blipChatMessages", JSON.stringify(chatMessages));
            }
        }
        if (openChat) {
            let lastUserChatMsgIndex = null;
            chatMessages?.map((msg, index)=>{
                if (msg.user) {
                    lastUserChatMsgIndex = index;
                }
            });
            if (lastUserChatMsgIndex != null) {
                if (document.getElementById("chat-message"+(lastUserChatMsgIndex+1))) {
                    document.getElementById("chat-message"+(lastUserChatMsgIndex+1))?.scrollIntoView({ block: 'start', behavior: 'smooth' });
                } else {
                    document.getElementById("chat-message"+(lastUserChatMsgIndex))?.scrollIntoView({ block: 'start', behavior: 'smooth' });
                }
            }
        }
    }, [chatMessages, openChat]);

    useEffect(()=>{
        if (document.getElementById("chatbot-draggable-container")) {
            document.getElementById("chatbot-draggable-container").style.right = "0.9%";
            document.getElementById("chatbot-draggable-container").style.left = "auto";
            document.getElementById("chatbot-draggable-container").style.top = "auto";
            document.getElementById("chatbot-draggable-container").style.bottom = "10px";
        }
    }, [isChatExpanded]);

    useEffect(()=>{
        if (transcript?.trim()) {
            let value = previousUserValue?.trim() ? previousUserValue?.trim() + " " + transcript.trim() : transcript.trim();
            if (value.length > 250) {
                value = value.slice(0, 250);
                resetTranscript();
                setTimeout(()=>{
                    setPreviousUserValue(value);
                }, 200);
                setIsShowInputErrMsg(true);
            }
            setCurrentUserValue(value);
        }
    }, [transcript]);

    useEffect(()=>{
        if (currentUserValue?.length <= 250) {
            setIsShowInputErrMsg(false);
        }
        if (!currentUserValue) {
            setPreviousUserValue("");
        }
        if (currentUserValue?.length) {
            if (listening && previousUserValue != currentUserValue) {
                let element = document.getElementById("chatbot-user-textbox");
                element?.focus();
                element?.setSelectionRange(currentUserValue?.length, currentUserValue?.length);
                element.scrollLeft = element.scrollWidth;
                if (showFeedbackMsg) {
                    setShowFeedbackMsg(false);
                    setSelectedFeedbackOption("close_chat");
                    let element = document.getElementById("chat-messages-box");
                    setTimeout(()=>{
                        element.scrollTop = element.scrollHeight;
                    }, 100);
                }
                if (showChatInfo) {
                    setShowChatInfo(false);
                    let element = document.getElementById("chat-messages-box");
                    setTimeout(()=>{
                        element.scrollTop = element.scrollHeight;
                    }, 100);
                }
            }
        }
    }, [currentUserValue]);

    const closeChat = (cancelApiRequest = true) => {
        if (cancelApiRequest) {
            source.cancel();
        }
        setOpenChat(false);
        setChatMessages([]);
        localStorage.removeItem("blipChatMessages");
        deleteCookie('user_session');
        setShowFeedbackMsg(false);
        setShowChatInfo(false);
        setSelectedFeedbackOption("close_chat");
        if (browserSupportsSpeechRecognition) {
            resetTranscript();
        }
    };

    const getDayOfWeek = (day) => {
        if (day === 0) {
            return "Sunday";
        } else if (day === 1 || day === -6) {
            return "Monday";
        } else if (day === 2 || day === -5) {
            return "Tuesday";
        } else if (day === 3 || day === -4) {
            return "Wednesday";
        } else if (day === 4 || day === -3) {
            return "Thursday";
        } else if (day === 5 || day === -2) {
            return "Friday";
        } else if (day === 6 || day === -1) {
            return "Saturday";
        }
    };

    const formatStandardDateTime = (date, refreshTime) => {
        const today = moment().startOf('day');
        const diff = today.diff(moment(date).startOf('day'), 'days');
        if (diff === 0) {
            return moment(date).format('hh:mm A');
        } else if (diff === 1) {
          return 'Yesterday ' + moment(date).format('hh:mm A');
        } else if (diff > 1 && diff < 7) {
            return getDayOfWeek(moment(date).day()) + " " + moment(date).format('hh:mm A');
        } else {
          return moment(date).format('MMM DD, hh:mm A');
        }
    };

    const callBlipService = async (message, option, user_session = null) => {
        if (message) {
            setCurrentUserValue("");
        }
        setShowFeedbackMsg(false);
        setShowChatInfo(false);
        setSelectedFeedbackOption("close_chat");
        if (message != "close_chat" && message != "close_chat_with_thumbs_up" && message != "close_chat_with_thumbs_down") {
            setIsTextfieldDisabled(true);
        } else {
            closeChat(false);
        }
        if (option) {
            setChatMessages((prevMessages) => [...prevMessages.slice(0, -1)]);
        }
        if (message) {
            if ((message != "welcome" && message != "close_chat" && message != "close_chat_with_thumbs_up" && message != "close_chat_with_thumbs_down") || option) {
                let chat_messages = [...chatMessages];
                for (let index = chat_messages?.length - 1; index >= 0; index--) {
                    if (chat_messages[index].user && chat_messages[index].time) {
                        let diff = moment().diff(moment(chat_messages[index].time), 'minutes');
                        if (diff === 0 && (moment().format("mm") === moment(chat_messages[index].time).format("mm"))) {
                            chat_messages[index] = { user: true, type: "text", message: chat_messages[index].message };
                        } else {
                            break;
                        }
                    } else {
                        break;
                    }
                }
                setChatMessages([...chat_messages, { user: true, type: "text", time: new Date(), message: (option ? option : message) }]);
            }
            const loadingMessage = {
                user: false,
                type: "loading",
            };
            if (message != "close_chat" && message != "close_chat_with_thumbs_up" && message != "close_chat_with_thumbs_down") {
                setChatMessages((prevMessages) => [...prevMessages, loadingMessage]);
            }
            const blipURL = `${process.env.REACT_APP_BLIP_API_URL}`;
            let requestPayload = new URLSearchParams({
                token: "FBC89765A88EFA03245CFC",
                user: candidateInfo?.id ? candidateInfo?.id : user_session ? user_session : getCookie('user_session') ? getCookie('user_session') : "",
                service: "dsaas",
                ask: message,
                env: `${process.env.REACT_APP_BLIP_ENV}`
            });
            const is_Session_Valid = await isSessionValid();
            if (is_Session_Valid) {
                const userToken = authHeader();
                await userToken
                .then((res) => {
                    requestPayload.append("idToken", res.Authorization?.split(" ")[1]);
                    requestPayload.append("accessToken", res["x-access-token"]);
                }).catch((e) => {});
            }
            source = axios.CancelToken.source();
            await axios.post(blipURL, requestPayload, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }, cancelToken: source.token })
            .then((response)=>{
                if (message != "close_chat" && message != "close_chat_with_thumbs_up" && message != "close_chat_with_thumbs_down") {
                    let data = response.data;
                    let answer = data.answer;
                    let actions = data.actions;
                    let c_text = "";
                    let blipResponse = [];
                    let followUpResponse = [];
                    let endConversationResponse = null;
                    let intent = "";
                    for (let index = 0; index < answer?.length; index++) {
                        c_text = (answer[index].caption ? answer[index].caption + "<br/>" : "") + answer[index].text;
                        if (c_text) {
                            if (answer[index].text === "close_chat") {
                                endConversationResponse = { user: false, type: "close_chat", message: c_text };
                            } else if (answer[index].type === "follow-up") {
                                followUpResponse.push({ user: false, type: "text", message: getChatWidget("text", c_text) });
                            } else if (answer[index].type === "message") {
                                blipResponse.push({ user: false, type: "text", message: getChatWidget("text", c_text) });
                            } else if (answer[index].type === "intent") {
                                // blipResponse.push({ user: false, type: "text", message: getChatWidget("text", c_text) });
                                intent = c_text;
                            }
                        }
                    }
                    if (actions) {
                        for (let index = 0; index < actions?.length; index++) {
                            let caption = actions[index].button_caption;
                            if (actions[index].type?.toLowerCase() == "url") {
                                if (actions[index].action) {
                                    blipResponse.push({ user: false, type: "link", message: actions[index], intent: intent });
                                }
                            } else if (actions[index].type?.toLowerCase() == "options") {
                                blipResponse.push({ user: false, type: "options", message: actions });
                                break;
                            } else if (actions[index].type?.toLowerCase() == "card") {
                                blipResponse.push({ user: false, type: "card", message: actions });
                                break;
                            }
                        }
                    }
                    blipResponse.push(...followUpResponse);
                    setIsTextfieldDisabled(false);
                    document.getElementById("chatbot-user-textbox")?.focus();
                    if (blipResponse.length) {
                        let lastResponse = blipResponse.pop();
                        if (lastResponse.type == "options") {
                            if (blipResponse.length) {
                                let newLastResponse = blipResponse.pop();
                                newLastResponse["time"] = new Date();
                                blipResponse.push(newLastResponse);
                                blipResponse.push(lastResponse);
                            }
                        } else {
                            lastResponse["time"] = new Date();
                            blipResponse.push(lastResponse);
                        }
                    }
                    if (endConversationResponse) {
                        blipResponse.push(endConversationResponse);
                    }
                    setChatMessages((prevMessages) => [...prevMessages.slice(0, -1), ...blipResponse]);
                }
            }).catch((error)=>{
                if (message != "close_chat" && message != "close_chat_with_thumbs_up" && message != "close_chat_with_thumbs_down") {
                    setIsTextfieldDisabled(false);
                    document.getElementById("chatbot-user-textbox")?.focus();
                    setChatMessages((prevMessages) => [...prevMessages.slice(0, -1)]);
                }
            });
        }
    };

    const activateFileClick = () => {
        document.getElementById("contained-button-file")?.click();
    };

    function dragElement(elmnt) {
        var pos1 = 0;
        var pos2 = 0;
        var pos3 = 0;
        var pos4 = 0;
        if (document.getElementById("chatbot-header-draggable-container")) {
            // if present, the header is where you move the DIV from:
            document.getElementById("chatbot-header-draggable-container").onmousedown = dragMouseDown;
        } else if (elmnt) {
            // otherwise, move the DIV from anywhere inside the DIV:
            elmnt.onmousedown = dragMouseDown;
        }

        function dragMouseDown(e) {
            e = e || window.event;
            e.preventDefault();
            // get the mouse cursor position at startup:
            pos3 = e.clientX;
            pos4 = e.clientY;
            document.onmouseup = closeDragElement;
            // call a function whenever the cursor moves:
            document.onmousemove = elementDrag;
        }

        function elementDrag(e) {
            e = e || window.event;
            e.preventDefault();
            setShowDragCursor(true);

            pos1 = pos3 - e.clientX;
            pos2 = pos4 - e.clientY;
            pos3 = e.clientX;
            pos4 = e.clientY;

            var newTop = elmnt.offsetTop - pos2;
            var newLeft = elmnt.offsetLeft - pos1;
            if (newTop < 0) {
                newTop = 0;
            }
            if (newLeft < 0) {
                newLeft = 0;
            }
            elmnt.style.top = newTop + "px";
            elmnt.style.left = newLeft + "px";
        }

        function closeDragElement() {
            setShowDragCursor(false);
            // stop moving when mouse button is released:
            document.onmouseup = null;
            document.onmousemove = null;
        }
    }

    const handleCloseChatbotGreetingPopover = () => {
        setAnchorEl2(null);
        if (!getCookie("isChatbotBeforeGreetingMsgClosed")) {
            setCookie("isChatbotBeforeGreetingMsgClosed", true, 360);
        }
    };
    const handleCloseIconClick = () => {
        let userMsgs = chatMessages?.filter((msg)=>(msg.user == true));
        if (userMsgs?.length) {
            if (showFeedbackMsg) {
                callBlipService(selectedFeedbackOption, null, getCookie("user_session"));
            } else {
                setShowFeedbackMsg(true);
                source.cancel();
                const element = document.getElementById("chat-messages-box");
                element.scrollTop = 0;
                if (browserSupportsSpeechRecognition) {
                    setIsMicOn(false);
                    SpeechRecognition.stopListening();
                }
            }
        } else {
            setOpenChat(false);
        }
    };
    const handleChatbotInfo = () => {
        setShowChatInfo(true);
        setShowFeedbackMsg(false);
        const element = document.getElementById("chat-messages-box");
        element.scrollTop = 0;
    };

    return (
        <Grid>
            <ChatbotGreetingPopper
                openChatbotGreetingPopover={openChatbotGreetingPopover}
                anchorEl2={anchorEl2}
                handleCloseChatbotGreetingPopover={handleCloseChatbotGreetingPopover}
            />
            {openChat ?
            showChatCrossIcon &&
            <Draggable
                onDrag={()=>{
                    setTimeout(()=>{
                        setIsDragging(true);
                    }, 100);
                }}
                onMouseDown={()=>setIsDragging(false)}
            >
            <div className={`chat-cross-svg ${(candidatePolicy &&
                JSON.parse(candidatePolicy)?.isPrivacyLatest === false &&
                JSON.parse(candidatePolicy)?.isSecrecyLatest === false &&
                JSON.parse(candidatePolicy)?.isTermsOfUseLatest === false) ? "chatbotNew-align" : "chatbotNew"}`} onClick={() => {
                if (!isDragging) {
                    setOpenChat((openChat)=>!openChat);
                }
            }}>
                <CloseIcon className='chatbot-outer-close-icon' />
            </div>
            </Draggable>
            :
            <div id="chatbot_logo" className={`${(candidatePolicy &&
                JSON.parse(candidatePolicy)?.isPrivacyLatest === false &&
                JSON.parse(candidatePolicy)?.isSecrecyLatest === false &&
                JSON.parse(candidatePolicy)?.isTermsOfUseLatest === false) ? "chatbotNew-msg-align" : (localStorage.getItem("token") && !isMobile && !window.location.pathname.includes("/message-list")) ? "chatbotNew-msg-align" : "chatbotNew"}`} onClick={() => {
                if (!isDragging) {
                    setOpenChat((openChat)=>!openChat);
                    handleCloseChatbotGreetingPopover();
                }
            }}>
                <Draggable
                    onDrag={()=>{
                        setTimeout(()=>{
                            setIsDragging(true);
                        }, 100);
                    }}
                    onMouseDown={()=>setIsDragging(false)}
                >
                    <Chatbot className='new-chatbot-svg' />
                </Draggable>
            </div>}
            {openChat && <><Grid className={`chatbot-main-container ${isChatExpanded ? 'chatbot-expanded' : 'chatbot-normal'}`} id="chatbot-draggable-container">
                <Grid className={`header-wrapper ${showDragCursor ? "cursor-grabbing" : "cursor-grab"}`} id="chatbot-header-draggable-container">
                    <Grid className='header-chat'>
                        <Grid className='header-top'>
                            <Grid className='header-top-left'>
                                <Grid className='mr-10'>
                                    <ChatbotHeaderIcon className='chatbot-header-svg' />
                                </Grid>
                                <Grid>
                                    <Typography className="header-top-left-text-title">hireHQ</Typography>
                                    <Typography className="header-top-left-text-sub-title">AI Assistant</Typography>
                                </Grid>
                            </Grid>
                            <Grid className="display-flex align-center justify-end cursor-hover">
                                <ChatbotInfoIcon
                                    aria-label="Info icon"
                                    onClick={handleChatbotInfo}
                                    onKeyPress={handleChatbotInfo}
                                    className='mr-10'
                                />
                                {!isChatExpanded ?
                                <ExpandIcon
                                    onClick={() => setIsChatExpanded(!isChatExpanded)}
                                    onKeyPress={() => setIsChatExpanded(!isChatExpanded)}
                                    aria-label="Expand icon"
                                    className='cursor-hover w-18'
                                />
                                :
                                <DeExpandIcon
                                    onClick={() => setIsChatExpanded(!isChatExpanded)}
                                    onKeyPress={() => setIsChatExpanded(!isChatExpanded)}
                                    aria-label="DeExpand icon"
                                    className='cursor-hover w-18'
                                />}
                                <MinimizeIcon
                                    onClick={() => setOpenChat(false)}
                                    onKeyPress={() => setOpenChat(false)}
                                    aria-label="Minimize icon"
                                    className='cursor-hover m-l-5'
                                />
                                <CloseIcon
                                    onClick={handleCloseIconClick}
                                    onKeyPress={handleCloseIconClick}
                                    aria-label="Close icon"
                                    className='chatbot-close-icon cursor-hover m-l-5'
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid lg={12} md={12} xs={12} className='text-wrapper'>
                    <Grid id="chat-messages-box" ref={messageContainerRef} lg={12} md={12} xs={12} className={`pt-30 pl-10 pr-10 pb-20 ${(chatMessages?.length && chatMessages[chatMessages.length - 1].type == "close_chat") || isShowInputErrMsg ? "" : "border-bottom-gray4"} ${isShowInputErrMsg ? "messages-wrapper-reduced-height" : ""} messages-wrapper`}>
                    {showFeedbackMsg ?
                    <ChatbotFeedback
                        selectedFeedbackOption={selectedFeedbackOption}
                        setSelectedFeedbackOption={setSelectedFeedbackOption}
                        callBlipService={callBlipService}
                        getCookie={getCookie}
                    />
                    :
                    showChatInfo ?
                    <ChatbotInfo setShowChatInfo={setShowChatInfo}/>
                    :
                    <>
                    {chatMessages?.map((message, index)=>(
                        message.type != "close_chat" ?
                        <Grid item lg={12} md={12} xs={12} key={index} className={`${message?.time ? "mb-15" : "m-b-5"}`}>
                        <Grid item lg={12} md={12} xs={12} id={`${"chat-message"+index}`} className={`display-flex ${message.user === true ? "justify-end" : "justify-start"}`}>
                            {(message.type != "text") ?
                                getChatWidget(message.type, message.message, message.intent)
                                :
                                <Typography className={`${message.user === true ? "chatbot-user-message" : "chatbot-message"} align-left pt-10 pb-10 pl-15 pr-15 f-14 display-flex inputcontrasts word-break`}>
                                    <span dangerouslySetInnerHTML={{ __html: message?.message?.replace("Welcome to SimplifyHire", "Welcome to hireHQ") }} />
                                </Typography>
                            }
                        </Grid>
                        {message?.time ?
                        <Grid item lg={12} md={12} xs={12}>
                        <Typography>
                            <Box className={`m-t-5 gray7 font-weight-400 display-flex ${message.user === true ? "justify-end m-r-5" : "justify-start m-l-5"} f-10`}>
                                {formatStandardDateTime(message?.time, refreshTime)}
                            </Box>
                        </Typography>
                        </Grid>
                        : ""}
                        </Grid>
                        : ""
                    ))}
                    </>
                    }
                    </Grid>
                    <Grid container lg={12} md={12} xs={12}>
                    {isShowInputErrMsg && <Typography className="chatbot-err-message-alignment border-bottom-gray4 danger-color f-12 font-weight-400">
                        Maximum character limit is 250
                    </Typography>}
                    </Grid>
                    <Grid container lg={12} md={12} xs={12} className='chatbot-footer-wrapper'>
                    {(chatMessages?.length && chatMessages[chatMessages.length - 1].type == "close_chat") ?
                    <>
                    <Grid item lg={6} md={6} xs={12} className='display-flex justify-center'>
                        <Typography className='f-14 cursor-hover font-weight-600 chatbot-restart-conersation-button ml-15'
                            onClick={() => {
                                setChatMessages([]);
                                localStorage.removeItem("blipChatMessages");
                                setShowFeedbackMsg(false);
                                setShowChatInfo(false);
                                callBlipService("welcome");
                            }}
                        >
                            Start Over
                        </Typography>
                    </Grid>
                    <Grid item lg={6} md={6} xs={12} className='display-flex justify-center'>
                    <Typography className='f-14 cursor-hover font-weight-600 chatbot-restart-conersation-button mr-15'
                        onClick={() => {
                            setChatMessages((prevMessages) => [...prevMessages.slice(0, -1)]);
                            setShowFeedbackMsg(false);
                            setShowChatInfo(false);
                        }}
                    >
                        Continue Chat
                    </Typography>
                    </Grid>
                    </>
                    :
                    // isShowInputErrMsg ?
                    // <Grid item lg={12} md={12} xs={12} className='display-flex justify-center'>
                    //     <Typography className="chatbot-invalid-input f-14 font-weight-400">
                    //         Maximum character limit is 250
                    //     </Typography>
                    // </Grid>
                    // :
                    <Grid item lg={12} md={12} xs={12}>
                    <Typography className="input-focus-none pl-10 pr-10 w-100 justify-between">
                        <TextField
                            id="chatbot-user-textbox"
                            type="text"
                            variant="standard"
                            autoComplete='off'
                            className="w-100 chatbot-input-field position-issue interview-questions p-r-0 send-questions"
                            margin="normal"
                            placeholder="Enter your message..."
                            value={currentUserValue}
                            onChange={(event)=>{
                                if (showFeedbackMsg) {
                                    setShowFeedbackMsg(false);
                                    setSelectedFeedbackOption("close_chat");
                                    let element = document.getElementById("chat-messages-box");
                                    setTimeout(()=>{
                                        element.scrollTop = element.scrollHeight;
                                    }, 100);
                                }
                                if (showChatInfo) {
                                    setShowChatInfo(false);
                                    let element = document.getElementById("chat-messages-box");
                                    setTimeout(()=>{
                                        element.scrollTop = element.scrollHeight;
                                    }, 100);
                                }
                                if (browserSupportsSpeechRecognition) {
                                    resetTranscript();
                                }
                                if (event.target.value?.length > 250) {
                                    event.preventDefault();
                                    setIsShowInputErrMsg(true);
                                    let value = event.target.value;
                                    setPreviousUserValue(value.slice(0, 250));
                                    setCurrentUserValue(value.slice(0, 250));
                                    return;
                                }
                                setPreviousUserValue(event.target.value);
                                setCurrentUserValue(event.target.value);
                            }}
                            onClick={(e)=>document.getElementById("chatbot-user-textbox").focus()}
                            onKeyPress={(event)=>{
                                handleSpaces(event);
                                if (event.target.value?.length >= 250) {
                                    event.preventDefault();
                                    setIsShowInputErrMsg(true);
                                }
                                if (event.code === "Enter") {
                                    if (event.target.value && !isTextfieldDisabled) {
                                        if (browserSupportsSpeechRecognition) {
                                            resetTranscript();
                                        }
                                        callBlipService(event.target.value);
                                    }
                                }
                            }}
                            // disabled={isTextfieldDisabled}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        {browserSupportsSpeechRecognition &&
                                            <Box className="display-flex align-center cursor-hover p-b-5">
                                                {listening && isMicrophoneAvailable ?
                                                    <div className='chatbot-mic-on' onClick={()=>{
                                                        setIsMicOn(false);
                                                        SpeechRecognition.stopListening();
                                                    }}>
                                                    <div className="chatbot-mic-pulse-ring"/>
                                                    <MicIcon
                                                        className='chatbot-mic'
                                                    />
                                                    </div>
                                                    :
                                                    <MicOffIcon
                                                        className='chatbot-mic chatbot-mic-off'
                                                        onClick={()=>{
                                                            setIsMicOn(true);
                                                            SpeechRecognition.startListening();
                                                        }}
                                                    />
                                                }
                                            </Box>}
                                    </InputAdornment>
                                ),
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Typography className="display-flex align-center my-job-list-align posted-align-view">
                                            {/* <Box className="break-all vertical-middle display-flex align-center">
                                            <Grid className="">
                                                <input
                                                    id="contained-button-file"
                                                    type="file"
                                                    accept=".png, .jpg, .jpeg, application/pdf, .doc, .docx"
                                                    style={{ display: "none" }}
                                                    // onChange={(e) => handleFileUpload(e)}
                                                    />
                                                    <a
                                                    href="javascript:void(0)"
                                                    onClick={activateFileClick}
                                                    onKeyPress={activateFileClick}
                                                    tabIndex={0}
                                                    className="gray7 text-decoration-none"
                                                    >
                                                    <img
                                                        style={{ width: "16px" }}
                                                        className="m-r-5 cursor-hover"
                                                        src={attachmentIcon}
                                                        alt="img"
                                                    />
                                                    </a>
                                            </Grid>
                                            </Box> */}
                                            {currentUserValue?.length > 0 &&
                                            <Box className="p-b-5 vertical-middle display-flex align-center">
                                            <CloseIcon
                                                onClick={()=>{
                                                    if (browserSupportsSpeechRecognition) {
                                                        resetTranscript();
                                                    }
                                                    setCurrentUserValue("");
                                                }}
                                                onKeyPress={()=>{
                                                    if (browserSupportsSpeechRecognition) {
                                                        resetTranscript();
                                                    }
                                                    setCurrentUserValue("");
                                                }}
                                                aria-label="Close icon"
                                                className='cursor-hover'
                                            />
                                            </Box>}
                                            <Box>
                                                {isTextfieldDisabled ?
                                                    <StopCircleOutlinedIcon
                                                        className={`cursor-hover ml-8 chatbot-stop-icon ${window.location.pathname === "/" ? "home-page-chatbot-stop-icon" : ""}`}
                                                        onClick={() => source.cancel()}
                                                    />
                                                    :
                                                    <SendIcon
                                                        aria-label="Clear icon"
                                                        className={`cursor-hover ml-8 ${window.location.pathname === "/" ? "home-page-chatbot-send-icon" : "chatbot-send-icon"}`}
                                                        onClick={(e) => {
                                                            if (currentUserValue && !isTextfieldDisabled) {
                                                                if (browserSupportsSpeechRecognition) {
                                                                    resetTranscript();
                                                                }
                                                                callBlipService(currentUserValue);
                                                            }
                                                        }}
                                                        onKeyPress={(e) => {
                                                            if (currentUserValue && !isTextfieldDisabled) {
                                                                if (browserSupportsSpeechRecognition) {
                                                                    resetTranscript();
                                                                }
                                                                callBlipService(currentUserValue);
                                                            }
                                                        }}
                                                    />
                                                }
                                            </Box>
                                        </Typography>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Typography>
                    </Grid>
                    }
                    </Grid>
                </Grid>
            </Grid></>}
        </Grid>
    );
};
export default chatbox;
