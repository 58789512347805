import { Grid, Typography, Box } from "@mui/material";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import TwitterIconsvg from "../../assets/images/twitter-icon.svg";
import FacebookIconsvg from "../../assets/images/FacebookIconFooter.svg";
import LinkedIconsvg from "../../assets/images/LinkedinIconFooter.svg";
import Constants from "../../helpers/constants";

export default function Footer() {
    let navigate = useNavigate();
    const appOrigin = useSelector((state) => state.location.origin);
    const [bionic, setBionic] = useState(localStorage.getItem("isBionic") ? localStorage.getItem("isBionic") : null);
    const [fixation, setFixation] = useState(localStorage.getItem("isBionic") ? JSON.parse(localStorage.getItem("bionic")) : null);
    const URLPath = location.pathname;
    const termsOfuse = (e) => {
        e.preventDefault();
        window.open("../termsofuse");
    };
    const privacyPolicy = (e) => {
        e.preventDefault();
        window.open("../privacypolicy");
    };
    const cookiePolicy = (e) => {
        e.preventDefault();
        window.open("../cookiepolicy");
    };
    const secrecy = (e) => {
        e.preventDefault();
        window.open("../secrecypolicy");
    };
    const goToClient = (e) => {
        e.preventDefault();
        navigate("../client");
    };
    const NormalFormat = (data, fixation) => {
        let color = fixation?.color;
        let fontSize = fixation?.font;
        let highlightedText;
        let coloredHtmlString;
        if (fixation?.bionicSelect === "bold") {
            highlightedText = textVide.textVide(data, { fixationPoint: fixation?.fixation, sep: ['<b>', '</b>'] });
            coloredHtmlString = highlightedText.replace(/<b>(.*?)<\/b>/g, `<b style="color: ${color} !important; font-size: ${fontSize}px !important">$1</b>`);
        } else {
            highlightedText = textVide.textVide(data, { fixationPoint: fixation?.fixation, sep: ['<u>', '</u>'] });
            coloredHtmlString = highlightedText.replace(/<u>(.*?)<\/u>/g, `<u style="color: ${color} !important; font-size: ${fontSize}px !important">$1</u>`);
        }
        const htmlContent = `<span>${coloredHtmlString}</span>`;
        return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
    };
    useEffect(() => {
        const handleStorage = () => {
            if (localStorage.getItem("isBionic")) {
                setBionic(localStorage.getItem("isBionic"));
                setFixation(JSON.parse(localStorage.getItem("bionic")));
            } else {
                setBionic(null);
                setFixation(null);
            }
        };
        window.addEventListener('storage', handleStorage);
        return () => window.removeEventListener('storage', handleStorage);
    }, []);
    return (
        <Grid>
            <Grid sx={{ py: 3, mt: 3 }} style={{ background: "#F9FAFB" }} className="footer-padding">
                <Grid>
                    <Grid
                        container
                        item
                        lg={12}
                        md={12}
                        xs={12}
                        spacing={1}
                        className="footerNav mt-10 justify-center body-container w-100"
                    >
                        <Grid item lg={4} md={6} xs={12} className="p-l-0 p-t-0">
                            <Typography className="cursor-hover navLogo p-t-5" onClick={()=>window.scrollTo(0, 0)}>
                                {appOrigin === "D" ?
                                    <img
                                        src={Constants.DEFAULT_GENERAL_LOGO_URL}
                                        alt="Logo" width="120px"
                                    /> : appOrigin === "N" ?
                                        <img
                                            src={Constants.DEFAULT_NEURODIVERSIFY_LOGO_URL}
                                            alt="Logo" width="120px"
                                        /> : <img
                                            src={Constants.DEFAULT_HEALTHCARE_LOG0_URL}
                                            alt="Logo" width="120px"
                                        />}

                            </Typography>
                            <Box className="small-text-font mt-20 launch-page-font navLogo">
                                {bionic ? NormalFormat("hireHQ is a technology solution developed to enable businesses to source and engage independent workers – and vice-versa. Both businesses and workers benefit in terms of transparency, time-to-value, quality of fit, and more.", fixation) :
                                    "hireHQ is a technology solution developed to enable businesses to source and engage independent workers – and vice-versa. Both businesses and workers benefit in terms of transparency, time-to-value, quality of fit, and more."}
                            </Box>
                            {appOrigin === "N" ?
                                <Box className="small-text-font mt-20 launch-page-font navLogo">
                                    Neurodiversify is a technology solution developed by Simplify to enable businesses to source and engage independent workers and vice-versa.  Both businesses and workers benefit in terms of transparency, time-to-value, quality of fit, and more.
                                </Box> :
                                <></>
                            }
                            <Box className="mt-10 navLogo">

                                <a href="https://www.linkedin.com/company/11181300/" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn icon" title="LinkedIn">
                                    <img src={LinkedIconsvg}
                                        className="footerIcon"
                                        aria-label="LinkedIn icon" />
                                </a>

                                <a href="https://www.facebook.com/simplifyworkforce/" target="_blank" rel="noopener noreferrer" aria-label="Facebook icon" title="Facebook">
                                    <img src={FacebookIconsvg}
                                      className="footerIcon"
                                        aria-label="Facebook icon" />
                                </a>

                                <a href="https://twitter.com/simplifychatter" target="_blank" rel="noopener noreferrer" aria-label="Twitter icon" title="Twitter">
                                    <img src={TwitterIconsvg}
                                        className="footerIcon p-b-5"
                                        aria-label="Twitter icon" />
                                </a>
                            </Box>
                        </Grid>
                        <Grid item lg={1} md={1} xs={12}></Grid>
                        <Grid item lg={2} md={2} xs={12} className="aboutCandidate">
                        </Grid>
                        <Grid item lg={2.5} md={2} xs={12} className="aboutCandidate">
                            <Typography className="font-weight-600 navLogo" variant="strong">
                                Legal
                            </Typography>
                            <ul className="p-l-0">
                                <li tabIndex={0} className="navLogo cursor-hover launch-page-font decorationcolor"
                                    onClick={termsOfuse}
                                    onKeyPress={termsOfuse}>
                                    {bionic ? NormalFormat("Terms of Use", fixation) : "Terms of Use"}
                                </li>
                                <li tabIndex={0} className="navLogo cursor-hover launch-page-font decorationcolor"
                                    onClick={privacyPolicy}
                                    onKeyPress={privacyPolicy}>
                                    {bionic ? NormalFormat("Privacy Policy", fixation) : "Privacy Policy"}
                                </li>
                                <li tabIndex={0} className="navLogo cursor-hover launch-page-font decorationcolor"
                                    onClick={cookiePolicy}
                                    onKeyPress={cookiePolicy}>
                                    {bionic ? NormalFormat("Cookies", fixation) : "Cookie Policy"}
                                </li>
                            </ul>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
